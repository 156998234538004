.settings-container {
    width: 100%;
    height: calc(100% - 58px);
    padding: 1.5625vw;

    .ant-tabs-tab {
        color: var(--Secondary-Text-Color, #979797) !important;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Manrope;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.53px;
    }

    .ant-tabs-tab-active {
        font-weight: 600 !important;
    }

    .ant-tabs .ant-tabs-tab,
    .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
        margin: 0;
        padding: 0.99vw 0.834vw;
    }
}



.settings {
    width: 100%;
    height: 100%;
}

.settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;

    .ant-tabs-top>.ant-tabs-nav {
        margin: 0;
    }

    .search-container,
    .add-role-container {
        display: flex;
        align-self: center;
        justify-content: center;
        gap: 30px;
    }

    .add-role {
        padding: 0;
        border: none;
        box-shadow: none;
        color: var(--primary-text-color, #333);
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: var(--font-size-h3, 16px);
        font-style: normal;
        font-weight: 600;
        line-height: 15.247px;
        letter-spacing: 0.53px;
        gap: 4px;

        img {
            width: var(--icon-width);
            height: var(--icon-height);
        }
    }


    .search-box {
        display: flex;
        width: 280px;
        height: 35px;
        padding: 5px 12px;
        align-items: center;
        border-radius: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border: var(--Number, 1px) solid var(--Line, #D9D9D9);
        background: var(--White, #FFF);

        .anticon-search {
            width: var(--icon-width);
            height: var(--icon-height);
            justify-content: end;
        }
    }
}

.ant-tabs-top {
    flex-direction: unset;
    height: 100%;
}

.ant-input-affix-wrapper>input.ant-input {
    color: #BABABA;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    letter-spacing: 0.53px;
}

.ant-input-affix-wrapper {
    font-size: 16px;
}

.ant-divider-horizontal {
    margin: 0 0 12px 0;
}

.general-settings {
    display: flex;
    height: calc(100% - 67px);
    gap: 24px;
    margin-top: 8px;
}

.profile-pic-container {
    display: flex;
    width: 245px;
    max-height: 253px;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--BG, #F3F4F8);
    margin: 0 24px;

    .pic-area {
        display: flex;
        height: 205px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    img {
        width: 120px;
        height: 42px;
        flex-shrink: 0;
    }

    .edit-icon {
        width: 100%;
        display: flex;
        justify-content: end;

        img {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }
}

.profile-form-container {
    width: calc(100% - 317px);
    height: 100%;

    .profile-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        height: 100%;

        .profile-form-field {
            display: flex;
            width: 311.25px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
        }

        .field-header-text {
            display: flex;
            gap: 4px;
            color: var(--Seconday-color, #B3B9BD);
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .profile-form-input {
            display: flex;
            width: 311.25px;
            height: 46px;
            padding: 12px var(--font-size-h3, 16px);
            align-items: center;
            gap: 10px;
            align-self: stretch;

            color: var(--primary-text-color, #333);
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .profile-form-button {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: flex-end;
            align-items: flex-end;

            Button {
                display: flex;
                height: 40px;
                padding: 4px 20px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 5px;
                border: 1px solid var(--main-color);
                background: var(--White, #FFF);

                color: var(--main-color);
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 15.247px;
                letter-spacing: 0.53px;
            }
        }
    }
}

.user-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    flex: 1 0 0;

    .ant-form-item {
        width: 100%;
    }

    .user-form-field {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .field-header-text {
        display: flex;
        gap: 4px;
        color: var(--Seconday-color, #B3B9BD);
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .user-form-input {
        display: flex;
        width: 100%;
        height: 46px;
        padding: 12px var(--font-size-h3, 16px);
        align-items: center;
        gap: 10px;
        align-self: stretch;

        color: var(--primary-text-color, #333);
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .user-form-select {
        width: 100%;
        height: 46px;
    }

    .ant-select-single .ant-select-selector {
        color: var(---primary-text-color);
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.action-items {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 24px;

    img {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}

.ag-theme-quartz.access-control-table {
    --ag-borders: none !important;
    height: 41vh;

    .ag-column-last {
        justify-content: start;

        .ag-header-cell-label {
            justify-content: start;
        }
    }

}

.ag-header.ag-header-allow-overflow .ag-header-row {
    background: #F3F4F8;
    color: #878787D9;
}

.ag-ltr .ag-cell {
    align-items: center;
    color: #333;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 15.247px; */
    letter-spacing: 0.53px;
}

.ag-theme-quartz.role-data {
    height: 58vh;

    .ag-ltr .ag-cell {
        color: var(--main-color);
        text-transform: capitalize;
        cursor: pointer;
    }
}

.access-settings {
    display: flex;
    flex-direction: column;
    gap: 0.834vw;

    .role-select {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0.834vw 0;

        label {
            color: var(--Seconday-color, #B3B9BD);
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .select {
            width: 338.25px;
            height: 46px;
            flex-shrink: 0;
            background: #FFF;

            .ant-select-selector {
                border-radius: 3px;
                border: 1px solid #DADADA;
            }
        }

        .ant-select .ant-select-selection-item {
            color: var(---primary-text-color);
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
        }
    }

    .table-select {
        display: flex;
        justify-content: center;
        align-items: center;

        .select-dropdown {
            width: 338.25px;
            height: 46px;
            flex-shrink: 0;
            background: #FFF;
        }

        .ant-select .ant-select-selection-item {
            color: var(--primary-text-color);
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}

.ant-select-dropdown .ant-select-item-option-content {
    color: var(--primary-text-color);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.04%;
}

.offcanvas-header {
    padding: 28px 28px 20px;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;

    .offcanvas-title {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        color: var(--primary-text-color);
        font-family: Manrope;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .btn-close {
        position: relative;
        left: 45%;
    }
}

.offcanvas-body {
    padding: 0 28px;
}

.asterisk {
    color: red;
}

.button-container {
    display: flex;
    gap: 16px;
    padding: 28px;
    justify-content: flex-end;
    align-items: center;
}

.add-button {
    display: flex;
    min-width: 100px;
    height: 46px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 5px;
    background: var(--main-color);

    color: #FFF;
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.add-button:hover {
    background: var(--main-color) !important;
    color: #FFF !important;
}

.cancel-button {
    display: flex;
    min-width: 100px;
    height: 46px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 5px;
    border: 1px solid var(--main-color);
    background: var(--White, #FFF);

    color: var(--main-color);
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media screen and (max-width: 1400px) and (min-height: 640px) {
    .settings-container {
        height: calc(100% - 38px);
    }

    .settings-header {
        height: unset !important;
    }

    .ag-theme-quartz.role-data {
        height: 50vh;
    }

    .ag-theme-quartz.access-control-table {
        height: 39vh;
    }

    .access-settings {
        .select-dropdown {
            width: 207px !important;
        }
    }
}

@media screen and (max-width: 1400px) and (max-height: 640px) {
    .settings-container {
        height: calc(100% - 38px);
    }

    .settings-header {
        height: unset !important;
    }

    .ag-theme-quartz.role-data {
        height: 50vh;
    }

    .ag-theme-quartz.access-control-table {
        height: 32vh;
    }

    .access-settings {
        .select-dropdown {
            width: 207px !important;
        }
    }
}