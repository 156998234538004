.home-layout {
  height: 100vh;
  background: var(--BG, #f3f4f8);
}

.side-navbar-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5625vw 0.63vw;
  gap: 0.26vw;

  .ant-menu-item {
    padding: 8px 15px !important;
    margin: 0;
    gap: 0.625vw;
  }

  .ant-menu-item-selected {
    .ant-menu-title-content {
      font-weight: 600;
    }
  }

  .ant-menu-title-content {
    display: flex !important;
    align-items: center;
    gap: 0.625vw;
    color: var(--primary-text-color);
    font-feature-settings: var(--font-feature-settings);
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-style: var(--font-style);
    font-weight: var(--font-weight-400);
    line-height: var(--line-height);
    letter-spacing: var(--letter-spacing);
  }
}

.ant-menu-item .ant-menu-item-selected,
.ant-menu-light .ant-menu-item-selected {
  background-color: #D7EEFE;

  a {
    color: #006FE6 !important;
  }
}

.ant-layout-sider-collapsed {
  .ant-menu-item {
    padding: 8px 10px !important;
    margin: 0;
  }
}

.home-content {
  display: flex;
  gap: 1.0417vw;
  margin: 1.0417vw 2.6vw 1.927vw 2.6vw;
  background: var(--BG, #f3f4f8);
}

.top-navbar {
  display: flex;
  height: 80px;
  padding: 1.563vw;
  margin: 1.927vw 2.6vw 0 2.6vw;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--Line, #d9d9d9);
  background: var(--White, #fff);

  .actions-container {
    display: flex !important;
    align-items: center;
    gap: 30px;
  }

  .notifications {
    width: var(--icon-width);
    height: var(--icon-height);
    cursor: pointer;
  }

  .quick-actions {
    color: var(--primary-text-color);
    font-feature-settings: var(--font-feature-settings);
    font-family: var(--font-family);
    font-size: var(--font-size-14);
    font-style: var(--font-style);
    font-weight: var(--font-weight-400);
    line-height: var(--line-height);
    letter-spacing: var(--letter-spacing);
    text-transform: uppercase;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    img {
      width: var(--icon-width);
      height: var(--icon-height);
    }
  }
}

.logo {
  width: 100px;
}

.user {
  display: flex;
  gap: 10px;

  .user-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
  }

  .user-name {
    color: var(--primary-text-color);
    font-feature-settings: var(--font-feature-settings);
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-style: var(--font-style);
    font-weight: var(--font-weight-400);
    line-height: 21.26px;
  }

  .user-role {
    color: var(--primary-text-color);
    font-feature-settings: var(--font-feature-settings);
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-style: var(--font-style);
    font-weight: var(--font-weight-600);
    line-height: 18.709px;
  }

  .profile-pic {
    width: 42px;
    cursor: pointer;
  }
}

.ant-layout-sider {
  flex: 0 0 256px !important;
  max-width: 256px !important;
  min-width: 256px !important;
  border-radius: 5px;
  border: 1px solid var(--Line, #d9d9d9);
  background: var(--White, #fff) !important;

  .ant-layout-sider-trigger {
    width: var(--icon-width) !important;
    height: var(--icon-height) !important;
    line-height: unset !important;
    background: #fff;
    position: relative;
    bottom: 98%;
    z-index: 1;
    border: 1px solid #D9D9D9;
    left: 95%;
    border-radius: 50%;

    .anticon svg {
      fill: #D9D9D9;
    }
  }

  .ant-menu {
    font-size: var(--font-size);
  }

  .toggle-button {
    width: var(--width-256);
    border: none;
  }

  .toggle-button-active {
    width: var(--width-80);
    border: none;
  }

  .ant-menu-inline,
  .ant-menu-inline-collapsed {
    height: 100%;
    border-radius: var(--border-radius);
  }
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: var(--width-80) !important;
  min-width: var(--width-80) !important;
  width: var(--width-80) !important;

  .ant-layout-sider-trigger {
    width: var(--icon-width) !important;
    left: 85% !important;
  }
}

.anticon {
  color: #000;
}

.content {
  border-radius: var(--border-radius);
  border: 1px solid var(--Line, #d9d9d9);
  background: var(--White, #fff);

  .breadcrumb {
    padding: 1.5625vw 1.5625vw 0;
    margin: 0;

    color: var(--primary-text-color);
    font-feature-settings: var(--font-feature-settings);
    font-family: var(--font-family);
    font-size: var(--font-size-14);
    font-style: var(--font-style);
    font-weight: var(--font-weight-400);
    letter-spacing: var(--letter-spacing);
    text-transform: uppercase;
  }
}

.ant-menu-title-content {
  a {
    text-decoration: none;
  }
}

.bread-crumb-item {
  color: var(--main-color);
  cursor: pointer;
  text-decoration: underline;
}


@media screen and (max-width:1500px) {
  .ant-layout-sider {
    .ant-layout-sider-trigger {
      width: var(--icon-width) !important;
      height: var(--icon-height) !important;
      line-height: unset !important;
      background: #fff;
      position: relative;
      bottom: 98%;
      z-index: 1;
      border: 1px solid #D9D9D9;
      left: 93%;
      border-radius: 50%;

      .anticon svg {
        fill: #D9D9D9;
      }
    }

    .ant-menu {
      font-size: var(--font-size);
    }

    .toggle-button {
      width: var(--width-256);
      border: none;
    }

    .toggle-button-active {
      width: var(--width-80);
      border: none;
    }

    .ant-menu-inline,
    .ant-menu-inline-collapsed {
      height: 95%;
      border-radius: var(--border-radius);
    }
  }

  .ant-layout-sider-collapsed {
    flex: 0 0 73px !important;
    max-width: 73px !important;
    min-width: 73px !important;
    width: 73px !important;

    .ant-layout-sider-trigger {
      width: var(--icon-width) !important;
      left: 83% !important;
    }
  }

  .side-navbar-menu {
    padding: 30px 12px;
  }

  .ant-layout-sider-collapsed {
    .ant-menu-item {
      padding: 8px 7px !important;
    }
  }
}