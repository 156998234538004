.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ag-theme-quartz {
  --ag-borders: none;
}

.ag-horizontal-right-spacer {
  display: table;
}

.ag-paging-panel {
  font-size: 16px;
  font-family: 'Manrope';
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-message .ant-message-notice-wrapper {
  text-align: center;
  display: flex !important;
  justify-content: end !important;
  padding: 1.5625vw 2.6vw !important;
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-success .ant-message-notice-content {
  display: flex !important;
  width: 400px;
  padding: 1.0417vw;
  align-items: center;
  gap: 0.83vw;
  border-radius: 0.52vw;
  border: 1px solid #4CAF50;
  background: #DAFEC5;
  box-shadow: 2px 2px 32px 0px rgba(0, 0, 0, 0.25);
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-error .ant-message-notice-content {
  display: flex !important;
  width: 400px;
  padding: 1.0417vw;
  align-items: center;
  gap: 0.83vw;
  border-radius: 0.52vw;
  border: 1px solid var(--custom-error, #FF5252);
  background: var(--red-lighten-4, #FFCDD2);
  box-shadow: 2px 2px 32px 0px rgba(0, 0, 0, 0.25);
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-warning .ant-message-notice-content {
  display: flex !important;
  width: 400px;
  padding: 1.0417vw;
  align-items: center;
  gap: 0.83vw;
  border-radius: 0.52vw;
  border: 1px solid var(--orange-darken-4, #E65100);
  background: var(--orange-lighten-4, #FFE0B2);
  box-shadow: 2px 2px 32px 0px rgba(0, 0, 0, 0.25);
}

.ant-message .ant-message-notice-wrapper .ant-message-custom-content {
  gap: 0.83vw;
  color: var(--Primary-Text-Color, #333);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: unset !important;
}

.ant-message .ant-message-notice-wrapper .ant-message-custom-content>.anticon {
  margin-inline-end: 0 !important;
  font-size: 18px !important;
}

.ag-watermark {
  display: none !important;
}