.customers-container {
  width: 100%;
  height: calc(100% - 58px);
  padding: 1.5625vw
}

.data-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: var(--font-family);

  .ant-tabs-top>.ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs .ant-tabs-tab,
  .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0;
    padding: 0.99vw 0.834vw;
  }

  .ant-tabs-tab .ant-tabs-tab-active {
    color: #006FE6;
  }

  .ant-divider-horizontal {
    background: var(--Line, #d9d9d9);
    height: 1px;
    margin: 0 0 12px 0;
  }

  .ant-tabs-tab {
    color: var(--Secondary-Text-Color, #979797);
    font-feature-settings: "liga" off, "clig" off;
    font-family: var(--font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.53px;
  }

  .ant-tabs-tab-active {
    font-weight: 600 !important;
  }
}

#myGrid {
  flex: 1 1 0px;
  width: 100%;
}

.data-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;
  height: 56px;

  .search-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  .add-clinic {
    padding: 0;
    border: none;
    box-shadow: none;
    color: var(--primary-text-color, #333);
    font-feature-settings: "liga" off, "clig" off;
    font-family: var(--font-family);
    font-size: var(--font-size-h3, 16px);
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    letter-spacing: 0.53px;
    gap: 4px;

    img {
      width: var(--icon-width);
      height: var(--icon-height);
    }
  }

  .search-box {
    display: flex;
    width: 280px;
    height: 35px;
    padding: 5px 12px;
    align-items: center;
    border-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: var(--Number, 1px) solid var(--Line, #D9D9D9);
    background: var(--White, #FFF);

    .anticon-search {
      width: var(--icon-width);
      height: var(--icon-height);
      justify-content: end;
    }
  }
}

.custom-no-rows {
  font-size: 18px;
  font-family: 'Manrope';
  font-weight: 400;
}

.ant-input-affix-wrapper>input.ant-input {
  color: #BABABA;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.247px;
  letter-spacing: 0.53px;
}

.more {
  cursor: pointer;
}

.ag-cell-wrapper,
.ag-cell-value {
  width: 100%;
}

.row-data-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  width: 100%;
  gap: 5px;
  font-feature-settings: "liga" off, "clig" off;
  font-family: var(--font-family);
  font-size: var(--font-size-h3, 16px);
  font-style: normal;
  line-height: 15.247px;

  .clinic-name {
    color: var(--Primary-Color, #006FE6);
    font-weight: 500;
    letter-spacing: 0.085px;
    /* text-decoration-line: underline; */
  }

  .full-name {
    max-width: 100%;
    color: var(--primary-text-color, #333);
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.53px;
    text-transform: capitalize;
  }

  .email {
    max-width: 100%;
    color: var(--Primary-Color, #006FE6);
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.53px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .phone {
    color: var(--primary-text-color, #333);
    font-weight: 400;
    letter-spacing: 0.53px;
  }

  .status {
    display: flex;
    padding: 7px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    text-align: center;
    color: #333;
    font-weight: 400;
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }

  .status-pending {
    border: 1px solid #ffb429;
    background: #ffdd9c;
  }

  .status-active {
    border: 1px solid var(--Status-Stroke, #97ff5b);
    background: var(--Status-Color, #dafec5);
  }

  .expired {
    color: #FF0000;
    line-height: 22px;
    text-transform: capitalize;
  }

  .login-pending {
    color: #ff9900;
    line-height: 22px;
  }

  .plan {
    color: var(--Primary-Color, #006FE6);
  }
}

.ant-dropdown .ant-dropdown-menu {
  width: 130px;
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  word-break: break-word;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  color: #333;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.53px;
  word-wrap: break-word;
  white-space: unset;
  padding: 2px;
}

.ant-dropdown-menu-title-content {
  a {
    text-decoration: none;
  }
}

.clinic-row-data-element {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  gap: 0.625vw;
  font-feature-settings: "liga" off, "clig" off;
  font-family: var(--font-family);
  font-size: var(--font-size-h3, 16px);
  font-style: normal;
  line-height: 15.247px;

  .clinic-name {
    color: var(--Primary-Color, #006FE6) !important;
    font-feature-settings: "liga" off, "clig" off;
    font-family: var(--font-family);
    font-size: var(--font-size-h3, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.085px;
    width: calc(100% - 55px);

    a {
      color: #006FE6;
    }
  }
}

.actions-list {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  gap: 16px;

  .options {
    display: flex;
    gap: 16px;
  }

  .approve {
    color: var(--Primary-Color, #006FE6);
    font-feature-settings: "liga" off, "clig" off;
    font-family: var(--font-family);
    font-size: var(--font-size-h3, 16px);
    font-style: normal;
    font-weight: 500;
    line-height: 15.247px;
    letter-spacing: 0.085px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .reject {
    color: #ff3e3e;
    font-feature-settings: "liga" off, "clig" off;
    font-family: var(--font-family);
    font-size: var(--font-size-h3, 16px);
    font-style: normal;
    font-weight: 500;
    line-height: 15.247px;
    letter-spacing: 0.085px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.ant-modal .ant-modal-content {
  padding: 28px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .header {
    color: var(--Text-Color, #333);
    font-family: var(--font-family);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-bottom: none;
    padding: 0;

    display: flex !important;
    align-items: center;
    justify-content: unset;
    gap: 8px;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .description {
      color: var(--Primary-Text-Color, #333);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.53px;
    }
  }

  .modal-field {
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
      color: var(--Seconday-color, #B3B9BD);
      font-family: var(--font-family);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .select-dropdown {
      height: 46px;
      background: #FFF;
    }

    .ant-select .ant-select-selection-item {
      color: var(--primary-text-color);
      font-family: var(--font-family);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .ant-input {
      display: flex;
      min-height: 90px;
      padding: 12px 16px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 3px;
      border: var(--Number, 1px) solid #DADADA;
      background: #FFF;
    }
  }

  .info-icon {
    width: var(--icon-width);
    height: var(--icon-height);
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    .cancel {
      display: flex;
      width: 100px;
      min-width: 100px;
      height: 46px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 5px;
      border: var(--Number, 1px) solid var(--Primary-Color, #006FE6);
      background: var(--White, #FFF);

      color: var(--Primary-Color, #006FE6);
      text-align: center;
      font-family: var(--font-family);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .reject {
      display: flex;
      min-width: 100px;
      height: 46px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 5px;
      background: var(--main-color);

      color: #FFF;
      text-align: center;
      font-family: var(--font-family);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .reject:hover {
      background: var(--main-color) !important;
      color: #FFF !important;
    }
  }
}

.ant-modal .ant-modal-footer {
  margin: 0;
}

/* Customers.css */
.ellipsis-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.pic-col {
  width: 42.52px;
  height: 42.52px;
  display: flex;
  align-items: center;
}

.clinic-profile-pic {
  width: 39px;
  height: 29px;
  border-radius: 50%;
}

.ag-theme-quartz {
  --ag-borders: none;
  --ag-active-color: none;
  --ag-range-selection-border-color: #fff;

  .ag-column-last {
    justify-content: end;

    .ag-cell-wrapper {
      width: unset;
    }

    .ag-header-cell-label {
      justify-content: end;
    }
  }



  .ag-ltr .ag-cell {
    border-right-width: 1px;
    display: flex;
  }
}

.ag-header-cell-sortable .ag-header-cell-label {
  color: rgba(135, 135, 135, 0.85);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.53px;
  text-transform: uppercase;
}

.add-clinic-canvas {
  width: 420px !important;
  padding: 28px;
  display: flex;
  gap: 20px;

  ::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background: #D9D9D9;
  }

  ::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #adadad;
  }

  .offcanvas-header {
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;

    .offcanvas-title {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      color: var(--Text-Color, #333);
      font-family: var(--font-family);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .btn-close {
      position: relative;
      left: 48%;
    }
  }

  .offcanvas-body {
    padding: 0;

    .ant-spin-nested-loading,
    .ant-spin-nested-loading .ant-spin-container {
      height: 100%;
    }
  }

  .clinic-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    justify-content: space-between;

    .clinic-form-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .clinic-form-div {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .clinic-form-row {
      width: 100%;
      gap: 24px;
    }

    .clinic-form-field {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .field-header-text {
        color: var(--Seconday-color, #B3B9BD);
        font-family: var(--font-family);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        gap: 4px;
      }

      .field-radio-text {
        color: #A3A3A3;
        font-family: var(--font-family);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .clinic-form-select {
        height: 46px;
        background: #FFF;
      }

      .ant-select .ant-select-selection-item {
        color: var(--primary-text-color, #333);
        font-family: var(--font-family);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .clinic-form-input {
        display: flex;
        height: 46px;
        padding: 12px var(--font-size-h3, 16px);
        align-items: center;
        align-self: stretch;
        border-radius: 3px;
        border: 1px solid #DADADA;
        /* background: #FFF; */

        color: var(--Text-Color, #333);
        font-family: var(--font-family);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .form-control {
        display: flex;
        width: 100%;
        height: 46px;
        align-items: center;
        align-self: stretch;
        border-radius: 3px;
        border: 1px solid #DADADA;
        background: #FFF;

        color: var(--Text-Color, #333);
        font-family: var(--font-family);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .clinic-form-sub-header {
      color: var(--Text-Color, #333);
      font-family: var(--font-family);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .clinic-form-logo-container {
      display: flex;
      height: 46px;
      padding: 12px var(--font-size-h3, 16px);
      align-items: center;
      justify-content: end;
      gap: 10px;
      border-radius: 3px;
      border: 1px solid #DADADA;
      background: #FFF;

      Button {
        display: flex;
        width: 68px;
        height: 24px;
        padding: 4px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 5px;
        border: 1px solid var(--Primary-Color, #006FE6);
        background: var(--White, #FFF);

        color: var(--Primary-Color, #006FE6);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: var(--font-family);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.247px;
        /* 108.904% */
        letter-spacing: 0.53px;
      }
    }

    .button-container {
      display: flex;
      gap: 20px;
      padding: 0;
    }
  }
}

.react-tel-input .selected-flag .arrow {
  margin-top: -3px !important;
  width: 14px !important;
  height: 9px !important;
  border: none !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='9' viewBox='0 0 14 9' fill='none'%3E%3Cpath d='M2 2L7.25 8L12.5 2' stroke='%23333333' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border: unset !important;
  box-shadow: none !important;
}

.react-tel-input .form-control:focus {
  box-shadow: none !important;
}

.react-tel-input .country-list .country-name {
  margin-right: 0 !important;
}

.react-tel-input .country-list .country {
  position: relative;
  padding: 12px 9px 13px 46px;
  color: var(--Primary-Text-Color, #333);
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  gap: 0;

  .dial-code {
    color: var(--primary-text-color) !important;
    font-family: var(--font-family) !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
  }

  .dial-code::before {
    content: '(';
  }

  .dial-code::after {
    content: ')';
  }
}

.react-tel-input .country-list .country.highlight {
  border-radius: 5px;
  background: var(--main-color) !important;
  color: var(--White, #FFF);

  .dial-code {
    color: var(--White, #FFF) !important;
  }
}

.ant-select-selection-placeholder {
  color: var(--Text-Color, #333);
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140.04%;
}

.ant-upload-wrapper .ant-upload-list {
  line-height: 0;
  position: absolute;
  left: 2%;
  top: 5%;
  width: 75%;
}

.ant-upload-list-item-name {
  color: var(--Text-Color, #333) !important;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions,
.ant-upload-icon {
  display: none;
}

@media screen and (max-width: 1400px) {
  .customers-container {
    height: calc(100% - 38px) !important;
  }

  .data-header {
    height: unset !important;
  }
}