@import '@coreui/coreui/dist/css/coreui.min.css';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-quartz.css";

:root {
  --main-color: #006FE6;
  --primary-text-color: #333;
  --padding: 16px;

  /* Font */
  --font-feature-settings: 'liga' off, 'clig' off;
  --font-family: Manrope;
  --font-size-14: 14px;
  --font-size: 16px;
  --font-style: normal;
  --font-weight-400: 400;
  --font-weight-600: 600;
  --line-height: 15.247px;
  --letter-spacing: 0.53px;

  /* Border */
  --border-radius: 5px;

  /* Width & Height */
  --icon-width: 24px;
  --width-80: 80px;
  --width-256: 256px;

  --icon-height: 24px;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.d-flex {
  display: flex;
}

.jc-center {
  justify-content: center;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.jc-around {
  justify-content: space-around;
}

.jc-between {
  justify-content: space-between;
}

.jc-evenly {
  justify-content: space-evenly;
}

.jc-right {
  justify-content: right;
}

.ai-center {
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}

.ai-start {
  align-items: flex-start;
}

.flex-col {
  flex-direction: column;
}