.resend-main-div {
    border-radius: 3px;
    background: #FFF;
    box-shadow: 3px 3px 27px 0px rgba(0, 0, 0, 0.05);
    width: 888px;
}

.ivorylogo {
    margin: 2.084vw 4.16vw 2.5vw 3.64584vw;
    /* width: 150px; */
    height: 4.838vh;
    flex-shrink: 0;
}

.check-your-inbox {
    color: var(--primary-text-color);
    font-family: var(--font-family);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.click-on-link {
    margin: 1.4584vw 0 1.667vw 0;
    padding: 0 6.25vw;
    font-family: var(--font-family);
    /* width: 585px; */
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.click-on-link-email {
    color: #000;
    font-family: var(--font-family);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.no-email-text {
    font-family: var(--font-family);
    margin: 0 0 1.35417vw 0;
    color: var(--Text-Color, #333);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.resend-btn {
    border-radius: 5px;
    background: var(--Primary-color, #006fe6);
    display: flex;
    width: 222px;
    height: 58px;
    padding: 12px 26px;
    justify-content: center;
    font-family: var(--font-family);
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: var(--Button-Text-color, #fff);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.wrong-address-text {
    width: 520px;
    color: var(--Seconday-color, #b3b9bd);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: var(--font-family);
    text-align: center;
}

.log-out-text {
    color: var(--Primary-color, #006fe6);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.resendmail {
    width: 213px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .ivorylogo {
        height: 6.8vh;
    }
}