.clinic-details-container {
    width: 100%;
    height: calc(100% - 52px);
    display: flex;
    padding: 1.5625vw;
    gap: 2.5vw;
    overflow: auto;

    .logo-container {
        display: flex;
        gap: 1.0417vw;
        width: 20%;

        .logo {
            width: 74px;
            height: 74px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            border: 1px solid var(--Secondary-Text-Color, #979797);

            img {
                width: 42px;
                height: 42px;
            }
        }
    }
}

.spinner {
    width: 100%;
    height: calc(100% - 52px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.name-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: calc(100% - 74px - 1.0417vw);

    .user-name {
        display: flex !important;
        gap: 8px;
        max-width: 100%;
    }
}

.clinic-name-row {
    max-width: 100%;
}

.clinic-name {
    max-width: 100%;
    color: var(--Primary-Text-Color, #333);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    letter-spacing: 0.53px;
}

.data-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 100%;

    label {
        color: #878787;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 15.247px;
        letter-spacing: 0.53px;
        text-transform: uppercase;
    }

    span {
        color: var(--Primary-Text-Color, #333);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.53px;
    }

    .active {
        line-height: 15.247px;
        padding: 7px 10px;
        border-radius: 5px;
        border: 1px solid var(--Status-Stroke, #97FF5B);
        background: var(--Status-Color, #DAFEC5);
    }

    .pending {
        line-height: 15.247px;
        padding: 7px 10px;
        border-radius: 5px;
        border: 1px solid #ffb429;
        background: #ffdd9c;
    }
}

.information-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;

    .ant-divider-horizontal {
        margin: 0;
        background: #BABABA;
    }

    .information-row {
        display: flex;
        align-items: flex-start;
        gap: 48px;
        align-self: stretch;

        .phone {
            display: flex;
            gap: 8px;
            align-items: center;
        }

        .email {
            display: flex !important;
            gap: 8px;
            color: var(--Primary-Color, #006FE6);
            text-decoration-line: underline;
            max-width: 90%;

            img {
                width: var(--icon-width);
                height: var(--icon-height);
            }

            .email-field {
                display: flex !important;
                gap: 2px;
                max-width: 100%;
                line-height: 22px;

                .copy-icon {
                    color: #979797;
                }
            }
        }

        .address {
            width: 180px;
        }

        .row-header {
            padding-top: 16px;
            color: var(--Primary-Text-Color, #333);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Manrope;
            font-size: var(--font-size-h3, 16px);
            font-style: normal;
            font-weight: 600;
            line-height: 15.247px;
            letter-spacing: 0.53px;
        }
    }
}

.edit {
    width: 2%;
    cursor: pointer;

    img {
        width: 24px;
        height: 24px;
    }
}

::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background: #D9D9D9;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #adadad;
}

@media screen and (min-width: 1200px) and (max-width:1500px) {
    .logo-container {
        width: 24%;
    }
}